import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss'
import './App.css';
import './Footer.css'
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-exports'
import { Alert, Button, Col, Nav, Navbar, Row, Spinner, Table } from 'react-bootstrap'
import '@aws-amplify/ui-react/styles.css';
import { AuthGetRequest, UnAuthGetRequest } from './APIGateWayRequestMiddleWare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faExchangeAlt, faToolbox, faTools, faCheckSquare, faAddressBook, faCalendarCheck, faFeed, faMessage, faBullhorn, faDashboard, faEnvelope, faStore, faUserCircle, faGear } from '@fortawesome/free-solid-svg-icons';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from 'react-router-dom';

//Imports of Screens:
import HomeScreenLanding from './HomeScreenComponents/Home';
import { BlankLogin } from './AuthenticatedPageWrapper/LoginBlank';
import { useEffect, useState } from 'react';
import OrgConfigureScreen from './AdminScreens/Org-ConfigurationScreen';
import GroupDashboard from './Toolbox/Dashboard/GroupDashboard'
import { LoggedInAuthPage, LoginRegisterPrompt } from './AuthenticatedPageWrapper/AuthPages';
import LandingPage from './AppointmentScreens/LandingPage';

import { CFormSelect } from '@coreui/react';
import { OrganizationManagementPortal } from './Organization Management/OrganizationManagement';
import { MySubscriptionContainer } from './Stripe/My Subscriptions Container'
import { BookingContainer } from './AppointmentScreens/BookingContainer';
import { UserProfileRootScreen } from './UserControlledScreens/Profile/UserProfileRoot'
import { JoinGroup } from './UserControlledScreens/JoinComponent/JoinGroup';
Amplify.configure(awsconfig);

function App() {
  //stripe:

  //Navigation Mobile:
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };
  //Cognito Code for user is logged in or Out:
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isOnLoginScreen, setIsOnLoginScreen] = useState(false);
  useEffect(() => {
    // Check the initial authentication state when the component mounts
    checkUser();

    // Subscribe to authentication events
    const authSubscription = Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signIn') {
        AuthGetRequest(`/v1/user/my-profile`)
          .then(response => {
            // handle success
            setUser(response.data.user);
          })
          .catch(error => {
            // handle error
            console.error("There was an error fetching user profile!", error);
          });
        checkUser();
      } else if (payload.event === 'signOut') {
        // User has signed out
        setUser(null);
        clearUserData(); // Call your function to clear user data
      }
    });

    // Clean up the subscription when the component unmounts
    return () => {
      authSubscription();
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      AuthGetRequest(`/v1/user/my-profile`)
        .then(response => {
          // handle success
          setProfileURL(response.data.user.profile_image);
          localStorage.setItem('profile_image_url', response.data.user.profile_image);
          setFirstName(response.data.user.first_name + " " + response.data.user.last_name);
          localStorage.setItem('DisplayName', response.data.user.first_name + " " + response.data.user.last_name)
          setUser(response.data.user);
        })
        .catch(error => {
          // handle error
          console.error("There was an error fetching user profile!", error);
        });
    }
  }, [isLoggedIn])

  async function checkUser() {
    try {
      await Auth.currentAuthenticatedUser();
      setIsLoggedIn(true); // User is authenticated
    } catch (error) {
      setIsLoggedIn(false); // No user is signed in
    }
  }
  async function signOut() {
    try {
      await Auth.signOut();
      localStorage.clear();
      window.location.reload(); // or redirect to the desired page using window.location.href = '/path'
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  }
  async function login() {
    window.localStorage.setItem('login-redirect-path', getTarget());
    window.location.href = '/login'
  }



  //Data User information
  const [profile_image_url, setProfileURL] = useState(localStorage.getItem('profile_image_url') || 'https://orgtools.app/company_logo.jpg');
  const [name, setFirstName] = useState(localStorage.getItem('DisplayName') || 'Loading ...');

  function clearUserData() { setUser(null) };

  function getTarget() {
    return window.location.pathname;
  }


  return (
    <div className="app-container">
      <header className="App-header" style={{ position: 'sticky' }}>
        <Nav className='navi-bar-dark'>
          <Table>
            <Row style={{ maxWidth: "100%" }}>
              <Col style={{ color: 'white' }}>
                {/* <Button className='' onClick={toggleNav} style={{backgroundColor:'transparent', borderColor:'transparent', color:'white'}}><FontAwesomeIcon icon={faTools} size='2x' style={{color:'White'}}/><div>Toolbox</div></Button> */}
              </Col>
              <Col className='user-account-menu'>
                <h3 style={{ backgroundColor: "transparent", color: 'white' }}>Org Tools Suite</h3>
              </Col>
              <Col>

              </Col>
            </Row>
            <Row className='mobile-nav-bar'>

            </Row>
          </Table>
        </Nav>
      </header>

      <div className="body-container">
        {window.location.pathname != '/login' && (<div className={`left-navigation ${navOpen ? "open" : "close"}`}>

          <NavigationLeftDesktop profileURL={profile_image_url} name={name}
            isLoggedIn={isLoggedIn} signOut={signOut} login={login} toggleNav={toggleNav} />
        </div>)}
        <div className="main-content">

          <Routes>
            {/* <Route path='/' element={<HomeScreenLanding isLoggedIn={isLoggedIn} login={login} />} /> */}
            <Route path='/my-profile' element={<LoggedInAuthPage element={<UserProfileRootScreen />} />} />
            <Route path='/my-subscriptions' element={<MySubscriptionContainer />} />
            <Route path='/my-organizations' element={<LoggedInAuthPage element={<OrganizationManagementPortal />} />} />
            {/**The route for subscriptions sign up page. There will be plan options for personal plans and for organizations */}
            <Route path='/groups/:orgID/dashboard' element={<LoggedInAuthPage element={<GroupDashboard />} />} />
            <Route path='/groups/:orgID/appointments' element={<LandingPage isLoggedIn={isLoggedIn} />} />
            {/**Path for Schedule Appointments '/groups/:orgID/my-appointments' */}
            {/**Path for Schedule Flow '/groups/:orgID/create-appointment' */}
            <Route path='/groups/:orgID/appointments/book-appointment' element={<BookingContainer />} />
            {/**Path for Joining a group '/groups/:orgID/join-org -- THis one will be a component that will get the parameters for joining a group
             * If the group is labled as a LDS Stake, then it will ask the user to select the Ward they belong to.
             * The flow for this part of the application will allow a user to join a sub-group(Ward Level) without needing Join approval. However the API
             * will set the permissions for that user to only have access to the Appointment scheduler. A member of the organization must apporve their joining for
             * access to other tools on the platform.
             * If the user is not in the Stake, then they can select the Not listed option which will just create their account under the Root Organization once their appointment has been scheduled
             * However they will not be able to access their account until leader has approved their joining.
             * Administrator users can then after approving them, select a sub-group of their memebership, they will remain members of the organization but to view the
             * hierchy, an API Endpoint will have to get the membership level and then recursively go up the the root group This can be displayed as breadcrumbs on
             * the top of the page so that they know what level of the organization they are booking an appointment.*/}
            <Route path='/login' element={<LoginRegisterPrompt element={<BlankLogin />} />} />
            {/**
             * Below are the adminitrative screens:
             */}
             <Route path='/admin/groups/:orgID/configure-organization' element={<LoggedInAuthPage element={<OrgConfigureScreen />} />} />
            <Route path='/*' element={(<div>
              <Alert variant='danger'>
                <h1>We are Upgrading!</h1>
                <p>Our website is undergoing a major upgrade to enhance your experience. All our platform tools are under construction and will be available in early 2024. We appreciate your patience and understanding.</p>
              </Alert>
            </div>)} />
          </Routes>

        </div>
      </div>


      <Footer toggleNav={toggleNav} />
    </div>
  );
}
function Footer(props) {
  const { toggleNav } = props;
  const [isDesktop, setIsDesktop] = useState(false);
  const [activeKey, setActiveKey] = useState('home');

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check initially

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <footer style={{height:'auto'}}>
      <Table>
        {/*Tis row is wil have colums that will hold buttons Each one will be outlined I need all the Col buttons to be distriubted evenly starting from the center, as more buttons are added They will get closer to each other*/}

        {/*This Row displays centered a the bottom of the screen*/}
        <Row className='footer-disclaimer desktop'>
          <Col className='mobileOnly'><Button className='mobileOnly' onClick={toggleNav} style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white' }}><FontAwesomeIcon icon={faToolbox} size='2x' /><div>Toolbox</div></Button></Col>
          <Col style={{height:"10px"}}>
            Org Tools & Solutions Inc. 2023© All Rights Reserved
            <p style={{ color: 'white', backgroundColor: 'transparent', height:"5px" }}>
              <small><small><small>Test Development Version 0.1.4</small></small></small>
            </p>
          </Col>
        </Row>
      </Table>
    </footer>
  );
}

function NavigationLeftDesktop(props) {
  const { profileURL, name, isLoggedIn, login, signOut, toggleNav } = props;

  const [selectedGroup, setSelectedGroup] = useState("Personal");
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [joinModalDisplayed, setJoinModal] = useState(false);
  const [toolbox, setToolbox] = useState({});
  const [loadingToolbox, setLoadingToolbox] = useState(false);

  const flattenGroups = (groups, level = 0) => {
    let flatList = [];

    groups.forEach(group => {
      flatList.push({
        orgId: group.orgId,
        groupName: '— '.repeat(level) + group.orgName,
        level: level
      });

      if (group.children && Object.keys(group.children).length > 0) {
        // As the children are an object, we convert them to an array before recursion
        const childrenArray = Object.values(group.children);
        const childGroups = flattenGroups(childrenArray, level + 1);
        flatList = [...flatList, ...childGroups];
      }
    });

    return flatList;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AuthGetRequest('/v1/user/enrollment-chain');
        console.log(response);
        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = response.data;

        // Assuming the data is an array with possibly one object that has children
        let processedGroups = [];
        if (Array.isArray(data) && data.length > 0) {
          processedGroups = flattenGroups(data);
        }

        setGroups(processedGroups);
      } catch (error) {
        console.error("Could not fetch groups: ", error);
      }
    };

    fetchData();
  }, []);


  function handleGroupChange(event) {
    const selectedGroupId = event.target.value;
    setSelectedGroup(selectedGroupId);
    console.log(selectedGroupId);
    setPermissions([])
    setToolbox({});
    setRoles([]);
    if (selectedGroupId === 'Join') {
      //alert("Joinging...")//TODO Trigger Join Group Flows and component
      setJoinModal(true);
    }
    else if (selectedGroupId === 'Personal') {

      return;
    }
    else {
      setLoadingToolbox(true);
      //Get toolbox first
      AuthGetRequest(`/v1/organizations/member-toolbox?orgId=${selectedGroupId}`)
        .then((response) => {
          if (response.status === 200) {
            setToolbox(response.data);
          }
          else {
            setToolbox(response.response.data);
          }
        }).catch((error)=>{
          console.log(error);
        })
        //Get roles to see if they have certain privilages
        AuthGetRequest(`/v1/organizations/leadership-toolbox?orgId=${selectedGroupId}`)
        .then((response)=>{
          if (response.status === 200) {
            setRoles(response.data.roles);
            setPermissions(response.data.permissions)
          }
          else{

          }
          
        }).finally(()=>{
          setLoadingToolbox(false);

        })
    }

  }
  const toggleJoinModal = () => {
    setJoinModal(!joinModalDisplayed);
    //setSelectedGroup('');
  }

  if (!isLoggedIn) {
    return (
      <div className='center-container'>
        <h1>Org Tools</h1>
        <p>Sign in or register to gain access to the toolbox</p>
        <Button onClick={login}>Login | Register</Button>
        <Button className='mobileOnly' onClick={toggleNav} variant='secondary' style={{ marginLeft: '20px' }}>Cancel Login</Button>
      </div>)

  }

  return (
    <div>
      <JoinGroup show={joinModalDisplayed} onHide={toggleJoinModal} />
      <div className="profile-section">
        <img src={profileURL} alt="Profile Picture" className="profile-image-placeholder" id='profileImage' />
        <div className="profile-name">{name}</div>
        <div className="role-list"><small>{roles}</small></div>
        <div className="organization-dropdown">
          <CFormSelect onChange={handleGroupChange} style={{ marginBottom: '5px' }} id='selected-org'>

            <optgroup label='My Account'>
              <option value="Personal">My Planner</option>
              <option value="Join">Join Group</option>
            </optgroup>
            <optgroup label='My Organizations'>
              {groups.map(group => (
                <option key={group.orgId} value={group.orgId}>{group.groupName}</option>
              ))}
            </optgroup>
          </CFormSelect>
        </div>
        {/**Change this to a select dropdown,
         * it will be a dropdown with organizations to select from.
         * The default will be My Self with the ID being 0,
         * then I need to call an API that gets the list of enrollments,
         * it will return in JSON an array of objects with these parameters: {groupID, groupName}
         * I will need to store the selected group so that I can call an API endpoint to get the list of tools enabled for a group.*/}
      </div>
      <div className="navigation-items">
        <div style={{ boxDecorationBreak: 'revert' }}><NavLink className='navigation-link' to={'/settings'} onClick={toggleNav}><FontAwesomeIcon icon={faGear} /> Settings</NavLink></div>
        <div><NavLink className='navigation-link' to={'/my-profile'} onClick={toggleNav}><FontAwesomeIcon icon={faUserCircle} /> Profile</NavLink></div>
        <div><NavLink className='navigation-link' to={'/my-subscriptions'} onClick={toggleNav}><FontAwesomeIcon icon={faStore} /> Subscriptions</NavLink></div>
        <div><NavLink className='navigation-link' to={'/my-organizations'} onClick={toggleNav}><FontAwesomeIcon icon={faTools} /> Manage Organizations</NavLink></div>
        <div><NavLink className='navigation-link' to={'/notification-center'} onClick={toggleNav}><FontAwesomeIcon icon={faEnvelope} /> Notifications</NavLink></div>
        <div><NavLink className='navigation-link' to={'/messages'} onClick={toggleNav}><FontAwesomeIcon icon={faMessage} /> Direct Messages</NavLink></div>
        
        {selectedGroup !== 'Join' && selectedGroup !== 'Personal' && toolbox && (
          <>
            <h4>My Organization Toolbox</h4>
            {loadingToolbox  && (<span><b>Loading your toolbox... <Spinner/></b></span>)}
            {toolbox.success === false && (<Alert variant='danger' style={{ wordWrap: 'break-word' }}>{toolbox.message}</Alert>)}

            {toolbox.success && toolbox.orgConfig.toolboxEnabled && (
              <>
                {toolbox.orgConfig.dashToolEnabled && (<div><NavLink className='navigation-link' to={`/groups/${selectedGroup}/dashboard`} onClick={toggleNav}><FontAwesomeIcon icon={faDashboard} /> Dashboard</NavLink></div>)}
                {toolbox.orgConfig.apptToolEnabled &&(<div><NavLink className='navigation-link' to={`/groups/${selectedGroup}/appointments`} onClick={toggleNav}><FontAwesomeIcon icon={faCalendarCheck} /> Appointments</NavLink></div>)}
                {toolbox.orgConfig.taskToolEnabled && (<div><NavLink className='navigation-link' to={`/groups/${selectedGroup}/my-tasks`} onClick={toggleNav}><FontAwesomeIcon icon={faCheckSquare} /> Tasks</NavLink></div>)}
                {toolbox.orgConfig.dirToolEnabled && (<div><NavLink className='navigation-link' to={`/groups/${selectedGroup}/directory`} onClick={toggleNav}><FontAwesomeIcon icon={faAddressBook} /> Directory</NavLink></div>)}
                {toolbox.orgConfig.calendarToolEnabled && (<div><NavLink className='navigation-link' to={`/groups/${selectedGroup}/calendar`} onClick={toggleNav}><FontAwesomeIcon icon={faCalendarAlt} /> Calendar</NavLink></div>)}
                {toolbox.orgConfig.feedToolEnabled && (<div><NavLink className='navigation-link' to={`/groups/${selectedGroup}/feed`} onClick={toggleNav}><FontAwesomeIcon icon={faFeed} /> Feed</NavLink></div>)}
                {toolbox.orgConfig.announcementToolEnabled && (<div><NavLink className='navigation-link' to={`/groups/${selectedGroup}/announcements`} onClick={toggleNav}><FontAwesomeIcon icon={faBullhorn} /> Announcements</NavLink></div>)}
              </>
            )}
          </>
        )}
        {(permissions.includes("Root_Admin") || permissions.includes("Task_Manager") || permissions.includes("Scheduler_Manager") || permissions.includes("Provider") || permissions.includes("Calendar_Manager") || permissions.includes("Enrollment_Manager")) && (
          <div>
            <h4>Administrative Tools</h4>
            {(permissions.includes("Scheduler_Manager") || permissions.includes("Provider") ) &&(<div><NavLink className='navigation-link' to={`/admin/groups/${selectedGroup}/scheduler-manager`} onClick={toggleNav}>Appointment Scheduler</NavLink></div>)}
            {permissions.includes("Task_Manager") && (<div><NavLink className='navigation-link' to={`/admin/groups/${selectedGroup}/task-manager`} onClick={toggleNav}>Task Management</NavLink></div>)}
            {( permissions.includes("Root_Admin") ||  permissions.includes("Enrollment_Manager")) && (<div><NavLink className='navigation-link' to={`/admin/groups/${selectedGroup}/enrollment-management`} onClick={toggleNav}>Enrollment Management</NavLink></div>)}
            {permissions.includes("Calendar_Manager") && (<div><NavLink className='navigation-link' to={`/admin/groups/${selectedGroup}/calendar-management`} onClick={toggleNav}>Calendar Management</NavLink></div>)}
          </div>
        )}
        {permissions.includes("Root_Admin")  &&(
          <div>
            <h4>Configure this Organization</h4>
            <div><NavLink className='navigation-link' to={`/admin/groups/${selectedGroup}/configure-organization`} onClick={toggleNav}>Configure Organization</NavLink></div>
          </div>
        )}

      </div>
      <div className='centered-container'>
        <Button onClick={signOut}>Sign Out</Button>
        <Button className='mobileOnly' onClick={toggleNav} variant='secondary' style={{ marginLeft: '20px' }}>Close</Button>


      </div>
    </div>
  )
}


export default App;
